import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "why-content-labels-matter"
    }}>{`Why Content Labels Matter`}</h2>
    <p>{`Many people who are blind or visually-impaired use an assistive technology called a screen reader to
access a computer or mobile device. A screen reader converts text into speech or Braille output. For
many people who use screen readers, UI text is how they interact with and experience a digital
product.`}</p>
    <p>{`Text-based experiences are also helpful for people with dyslexia and other cognitive or learning
disabilities who need to see and hear the content to better understand it.`}</p>
    <p>{`Some users experience situational impairments like a slow internet connection. In this case, images
might not load, and users will need a faster loading content-only experience.`}</p>
    <h2 {...{
      "id": "what-is-alt-text"
    }}>{`What is Alt-Text?`}</h2>
    <p>{`Alternative-text (or alt-text) is a text description of a static image, graphic, illustration, data
visualization, or other visual-content. Providing alt-text ensures that all users are able to have
an equitable experience and receive the same level of information and value from a UI.`}</p>
    <p>{`In the context of the document, alt-text is the text equivalent to the image and is required if the
image is non-decorative.`}</p>
    <p>{`Alt-text should be considered early in the design process, not just as an afterthought. When
creating images or graphics, make sure that the content has alt text to ensure an equitable
non-visual experience.`}</p>
    <h3 {...{
      "id": "alt-text-vs-captions"
    }}>{`Alt-Text vs. Captions`}</h3>
    <p>{`Alt-text is not the same as a caption. `}<strong parentName="p">{`Alt-text`}</strong>{` describes the image for people who can’t see it.
`}<strong parentName="p">{`Captions`}</strong>{` are extra information about that image for both users who can and cannot see the image.`}</p>
    <p>{`A caption is not a substitute for alt-text. If there is a caption for an image, there must also be
alt-text.`}</p>
    <h3 {...{
      "id": "example"
    }}>{`Example:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/49934a159e5b8559c8956358a639d260/a2b91/alt-text-example-stickies.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.21621621621622%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAADJ0lEQVR42i3R7VMTBADH8f0NMhiMRzcGExhCztFiY4xlcNoIGzCGLJlrgDAYjDFBOVk8aJx07AS242GsMcIHvEYoGSFo1GWU6aWnvbDrLjvu6up61wtffaOut78Xn7vffQUD3W10NJ7AWm+h2ngMe20VrpMWDCUatCU6yjUqShUyPPVH6G+soNdaTnGREuUrBaSmpFKqVXOkKJckkYikxCQEjx8+4PzZLgZ9Zxjtc+Hva2W81cQZ02t4a/WMue0M2qsYthsJ9DUy2tNIjlyORCIh+V/wcD76Atn/oAjB3y9fsrb+KYtXIwT8w4x5TjJiq2Cw6TgD7VbGfF4u9rZz0WnhA5cFe1UpkowMMiUZZGdKkcukqPKkpIoTEe+hghe7uzz/6Tnff7dDdHyIqU4Ll7xN9EyEcfgXcQav4/Z0020uw2PS4qws4nSlGpdJQ8/eVluuI0+WTro4nqSEeAS//PqC3T9+5/Gzp6xcW2B6oIMJl5lp3xAXzvlwn3+PU85OasoO02zIo99Wibm0kLeLFdQZVKgUORzYn4g0JQFRvBDBb3/9yY/PnvDt1m1WF2aZf/8cEx0N+JurCXbWEPTU4O94i0stZVyofxWdUoFQJEYojGdfXBwymQzjG1qkyUJEwjgEPzx9wqNHD9j4ZJHV5SnWYgGigR5mRuxEI24+inQSuuxgbsLG0CkDmsJcDuTk/Ve68qiBBnMVpmN6MpPjSE7Yh+Du1ibb9z7nq28+Zv1OkNjNYa7dOkt4qo258SYi0XYiC61MeqpxVenoMpfTXmek06THfFSLVq1EXZiNIjOFNNEeeP/+Btvba2xtLrP99QIrt0cIzziZnXKwFOslPN3C/LSDUfebWIvlWEsPYjiUi/qgnJysDNIz0pFny9Eo85GK9y7vPIxx74srbN4Nc+fLSa7GvETCLib765iPOlhYbiM0Y2M2ZMXxjpJq9X6cFQVUa/NJT0tFnpVFiVpFrfF1FNI0BBvrS3y29iGrK5dZuTXM9Zu9XFnxEgq8SyjUTPTGaeaWmgiGGxgPGOltOUSLPgerLh9fl5Vhr42fdxaJhQYpKSrkH0wM6weMia27AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Photo of people writing ideas on sticky notes.",
            "title": "Photo of people writing ideas on sticky notes.",
            "src": "/static/49934a159e5b8559c8956358a639d260/50383/alt-text-example-stickies.png",
            "srcSet": ["/static/49934a159e5b8559c8956358a639d260/1efb2/alt-text-example-stickies.png 370w", "/static/49934a159e5b8559c8956358a639d260/50383/alt-text-example-stickies.png 740w", "/static/49934a159e5b8559c8956358a639d260/a2b91/alt-text-example-stickies.png 3360w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Sticky notes are a great tool for brainstorming.`}</em></p>
    <pre><code parentName="pre" {...{}}>{`<figure>
  <img src="brainstorming.jpg" alt="Photo of people writing ideas on sticky notes." />
  <figcaption>Sticky notes are a great tool for brainstorming.</figcaption>
</figure>
`}</code></pre>
    <h3 {...{
      "id": "best-practices-for-writing-alt-text"
    }}>{`Best Practices for Writing Alt-Text`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Be Concise:`}</strong>{` An image is worth a thousand words, but would you read or listen to them all? 80
characters is a common width for
`}<a parentName="p" {...{
            "href": "https://www.afb.org/node/16207/refreshable-braille-displays"
          }}>{`refreshable Braille displays`}</a>{`. Don't
make your users scroll through an essay of your image's description.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Keep It Casual:`}</strong>{` Describe the image as if you were describing it aloud to a friend.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Focus on Value:`}</strong>{` Not every detail of the image needs to be described. Only describe parts of
the image that add information not already available in the UI text. Ask yourself: “Why is this
image included in the design? What is this image communicating to users?”`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use Proper Grammar:`}</strong>{` Use punctuation and capitalization correctly as this directly relates to
the listening experience.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Be Specific:`}</strong>{` Don't include the word “image” in the description. Consider using more specific
descriptors like "diagram," "flow chart," “illustration,” or “photo” if additional context is
needed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ask, Is It Decorative?:`}</strong>{` Images or graphics that are purely decorative and provide no
additional value to the user do not need alt-text. They can be marked as decorative so they are
skipped by screen readers.`}</p>
      </li>
    </ul>
    <p>{`For more information on how and when to use alt-text, check out
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/images/decision-tree/"
      }}>{`W3C’s alt Decision Tree`}</a>{`.`}</p>
    <h3 {...{
      "id": "alt-text-example"
    }}>{`Alt-Text Example:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/73c9836346a574d50a946c2346a39cf2/b0b73/alt-text-example-meeting.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.75675675675676%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGt0j5ZDLB//8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIDBCEAERL/2gAIAQEAAQUCWxG7+8NmPsQqvGwnT//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BrX//xAAZEAEBAAMBAAAAAAAAAAAAAAABABAREjH/2gAIAQEABj8CNMrexyZ//8QAGxAAAwEAAwEAAAAAAAAAAAAAAAEhETFBYXH/2gAIAQEAAT8hiBJp0ibwaG/DIUXrrMvaVo+jZ//aAAwDAQACAAMAAAAQ+N//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EEthR//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QQFjl/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAITFBUXGBwf/aAAgBAQABPxADWA0EvS5hJyNhd0CfkzIDyJjrXHkUSA0UE6LqGuZJml1E5ZeTZn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "photo of woman placing sticky notes on wall in a meeting",
            "title": "photo of woman placing sticky notes on wall in a meeting",
            "src": "/static/73c9836346a574d50a946c2346a39cf2/07f3a/alt-text-example-meeting.jpg",
            "srcSet": ["/static/73c9836346a574d50a946c2346a39cf2/f4308/alt-text-example-meeting.jpg 370w", "/static/73c9836346a574d50a946c2346a39cf2/07f3a/alt-text-example-meeting.jpg 740w", "/static/73c9836346a574d50a946c2346a39cf2/b0b73/alt-text-example-meeting.jpg 3600w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Photo by `}<a parentName="em" {...{
          "href": "https://unsplash.com/photos/Oalh2MojUuk"
        }}>{`You X Ventures`}</a>{` via
`}<a parentName="em" {...{
          "href": "https://unsplash.com/"
        }}>{`Unsplash`}</a></em></p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Provide a high level overview of the image's value. Ex. "photo of woman placing sticky notes on
wall in a meeting"`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Describe every detail of the photo. Ex. "A group of busy coworkers are all working around a messy
conference table in an airy room with white walls. One woman wearing a white shirt is placing
orange, yellow and pink sticky notes on a wall. There are 3 laptops, a plant, some notebooks and
pads of sticky notes on the table."`}</li>
      </ul>
    </Dont>
    <h3 {...{
      "id": "decorative-image-example"
    }}>{`Decorative image Example:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5bb7c80e0d9742683ecef4c9000e0b03/a2b91/alt-text-example-design-site.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.83783783783784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABiklEQVR42q2SO0sDQRDH8wksRARtxNrGSrBTEMHKRvATCFZpgqiFhYhptLAQERsRQRsbwQfiAwQDKkIkMRx5EJQoRC/ZnJ5Gk7u9n5dcFJS8EAeG3Zn974+ZnXU96SYFsyz+xVzVgIZhFN00TaSU314V+FgGaJWCRCKB3+8nEAgQiUQIBoOoqvpDU1eFX2IhBIqiEI1Gi8BwOIyu639r2cjnSKUFmpYhlVLJiBQinSajaRWrq/mGdpZCWpa8oCm8YbUB/gRaDoDXNKEFD+vXKvfJC85v5ni+XYaXIJbxjqy/ZYtilBPMLKzRsJqh17vOyWY3cd8gpq8bU3ETSghM6ehrAp1FI6ZMMb67xdjSPCOLHjzb02iXnURWmomfTlac9K+WnT3JDYyDRvviBP6jAT52WjhbamfP3cTjaCNvx53wHishZDWgdCq886L7hlHOJzjc7ye+3cfIUAc9ba3EZrt4uBq2/9RhqRuzjinLD5udtbUv9v/ReX0WCDXJWy5LLp+1z223jLJD+QRxxIWbwlS1BAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of Canvas site homepage",
            "title": "Screenshot of Canvas site homepage",
            "src": "/static/5bb7c80e0d9742683ecef4c9000e0b03/50383/alt-text-example-design-site.png",
            "srcSet": ["/static/5bb7c80e0d9742683ecef4c9000e0b03/1efb2/alt-text-example-design-site.png 370w", "/static/5bb7c80e0d9742683ecef4c9000e0b03/50383/alt-text-example-design-site.png 740w", "/static/5bb7c80e0d9742683ecef4c9000e0b03/a2b91/alt-text-example-design-site.png 3360w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`The playful illustration on the homepage of our Workday design site is decorative. It does not
provide any additional information to users so it does not have alt-text and is marked as
decorative.`}</li>
    </ul>
    <h2 {...{
      "id": "what-is-non-text-content"
    }}>{`What is Non-Text Content?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/text-equiv-all.html"
      }}>{`Non-text content`}</a>{` is any
interactive content in the UI, which is not text or an image. Examples include:
`}<a parentName="p" {...{
        "href": "/styles/assets/overview"
      }}>{`icons`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/buttons/button"
      }}>{`icon-only button variants`}</a>{`, and interactive
data visualizations. Similar to images, which require alt-text, non-text content requires
descriptive labels in the code to ensure an equitable experience for all users. Like alt-text,
labels for non-text content are not visible in the UI.`}</p>
    <h3 {...{
      "id": "best-practices-for-writing-non-text-content-labels"
    }}>{`Best Practices for Writing Non-Text Content Labels`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Focus on Purpose:`}</strong>{` Non-text content labels should explain the value of the content, what it
does, rather than just what it looks like.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use Proper Grammar:`}</strong>{` Use punctuation and capitalization correctly as this directly impacts the
listening experience.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Avoid Duplication:`}</strong>{` To avoid audible duplication of interactive elements, don't include the
state (pressed, disabled, etc...), or the word 'button' or 'link' in the description.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Distinguish Functionality:`}</strong>{` If multiple buttons on the same page have the same visual, they
need uniquely distinguishable labels to make their functionality clear.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Consider Complexity:`}</strong>{` More complicated non-text content like a data visualization may require
both a short text label or title as well as a longer description of the content’s details.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Data Visualization Considerations:`}</strong>{` For complex or dynamic data visualizations, the non-text
content description might be represented with a table. Adding a caption can be useful for
describing the analysis of, or main take-away from, the data visualization.`}</p>
      </li>
    </ul>
    <p>{`For a deep dive into how the brain processes data visually and how you can leverage different senses
to provide an equivalent experience for people with disabilities, check out
`}<a parentName="p" {...{
        "href": "https://technica11y.org/data-verbalization"
      }}>{`Data Verbalization by Doug Scheppers`}</a>{` from Tenon’s
Technica11y webcast series.`}</p>
    <h2 {...{
      "id": "non-text-content-label-examples"
    }}>{`Non-Text Content Label Examples:`}</h2>
    <h3 {...{
      "id": "icon-button-examples"
    }}>{`Icon Button Examples`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5b102b86a1d268ca558fe367e54f2fe6/f8836/alt-text-example-org-chart.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.4054054054054%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAACNUlEQVR42qWUwW/TMBTG+69z48CJO/wHXBAIJNgBJEAcBhKwsW7QlaZNm9h17NhxYqfpx3teoRUaQmNWn/L82d8vz7Hd0brSuC7EusLZxTecnI3T83R8ji9fx7icr/A3D8fo+oEKpXbQzsP6FkJbCONQWwuZfUZZmRsCVQVRN+C2GbZ4cO8uXj9/mvqhcwSs00tvBJQ7YDcAb+7fwY8XDxmHnvr/CfQJ6NsO0+P3WBw/QvQ5etwSOJ1OMZ5c4vR8CiEVhtsCN5setTEwWqe83zLQ3hwoCBipnJ42JWyGFPRDS2su9T+AaheHuaCjwUY+MuUuUk7VSWWu9SRgSQc4p2+zpBBU2fJ3rrCSa+RijXKtUJDGeUFaqTjncYU//SNpqHzfQboOk8pDNZRzkM5a5QNm2mNZt1A+QlpP39eRJ6Ck8csDz1rXGDV0E7idVhEvlx2+133q24Y2ZTtAhwGvVgFvi6sz2Pd9OkrcPqmII/LM3JXHWLcHHsuIx1mXwIdA0Q54tgg4yjsE2qBD4Lsy4Al5LkzcA3VNZ0oKZKsSH6YrFELC0p1tuwDn6D5XCidzgUmhYCi3pLUhJMBSVvg4K9A0zQ5ouUIPbTRmWYYiXyCbz5PJd1QRGYtSYDHPkC8WkLQ5Hekd6TFG0nLk7KM52+2W/jwOlhxoQsuQeFW+oxcNw5AmtrREHuMW437JDGV/32/2Sza1TaJPppCMvm3BuqPvyDlrPMZzrGtSJaz/8vCT+5Wp8RMffA2JJzM5TAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of Workday Org Chart",
            "title": "Screenshot of Workday Org Chart",
            "src": "/static/5b102b86a1d268ca558fe367e54f2fe6/50383/alt-text-example-org-chart.png",
            "srcSet": ["/static/5b102b86a1d268ca558fe367e54f2fe6/1efb2/alt-text-example-org-chart.png 370w", "/static/5b102b86a1d268ca558fe367e54f2fe6/50383/alt-text-example-org-chart.png 740w", "/static/5b102b86a1d268ca558fe367e54f2fe6/f8836/alt-text-example-org-chart.png 1214w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Describe the purpose of the icon or the action that it performs. Ex “Related Actions Allison
Hunter”`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Describe what the icon looks like. Ex “rectangle with three dots”`}</li>
      </ul>
    </Dont>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/08566073d707bd20b2037415b6a66208/0f529/alt-text-example-options.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.432432432432435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVR42sWSzW7EIAyE8/5v1Kforbde9lCt1G5C+AcbpoYkqyS7atVTLY0iIvjGDB6M8yildNXaVA/Cad1EOYOIoLSGtRazNhiVhnERg7UealK43UZ8iVImrBwwV4yOkUiMShXTRcylK6WMLPuzfEOIcpYxvLxeYazpIGMd1KwRZUNbW5/wdlF4vxqwgEggxPWu/m9VIu5nBmUj9DxLhzcYYzvQSQzeB2jp3oYMFwn74i2OfdWl84GZMY6jAHzf1IECCzFKJuF0piLmgkAtioIsgIORdDrUk5t1DlkCb24xHTsrYu5ihpeOlSMxpSXsM3BfHbgGrSWCmFKHt2ovO0s8kzziNLVbuUMzT4EN4kPAbAIuH5+Y1jiW0ao/imQangKdAKzk6GK6z942Kr/pAUhrfpvrX+sBuF8/jMZ/AL8BU+yxC8gqlQYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of Workday discovery boards",
            "title": "Screenshot of Workday discovery boards",
            "src": "/static/08566073d707bd20b2037415b6a66208/50383/alt-text-example-options.png",
            "srcSet": ["/static/08566073d707bd20b2037415b6a66208/1efb2/alt-text-example-options.png 370w", "/static/08566073d707bd20b2037415b6a66208/50383/alt-text-example-options.png 740w", "/static/08566073d707bd20b2037415b6a66208/0f529/alt-text-example-options.png 1259w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Provide uniquely distinguishable information in the label to contextualize the functionality of
the button. Ex “Mark Options”`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Give multiple buttons with different actions the same label on a page. Ex “Options”`}</li>
      </ul>
    </Dont>
    <h3 {...{
      "id": "chartgraph-example"
    }}>{`Chart/Graph Example`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/79628aaf685e38e6c20aca3b0384a895/64d87/alt-text-example-graph.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.1081081081081%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3UlEQVR42p1UWW7bMBDV/e/Qj54jF2jRIg3Qoh8JHASBZcWWtYTivr3OUF6aNHLaEngYccSZeSTfsJJSQilVIKVCzhkppYIYE7QxmHiNZqvgQ0Au/yIi2UEI3K7u0fYDeFSBFsyIxaaUT4gxl0DkAPA8zElOa6g4F5BKw3kPrTUqvDMoBr3O2DsLKlccvIu3hrX2csJA8d/WwI8GWCmBG/GErRjhnV+MqZZY8bjdAffd2T8Gi59qf3FHbybkc7PW4OtjgvJHtqnYq+0dZHBz4b9OyLdIQd83CY04h45e4+PqC11G+jeGx6GIyKeHjAfa9t044sPqM27aNaL1yP+TsNwcKeaRJHbddqj1eDjjvMxQ0EEr0o8i0e6NRCdFEbgmIQ86FWvUhODdYrJ8kFIuCeUET4u9c3DBk0DJ0rcnoXoSMlt7mKcUC/jSuAliDNRhEnVdo16vsXuWqJqnbWkpFqU1drYnmBdzQ23I4DZt2xZN0xA2lJCwadD1PapeaGwH6mXtSsVzK84oTAlL/kjfHeXYE5ht9UxX2U0Ok/al6dMrlEcgxj/8R3AXCCIzUh6tFapBGuxGUxyRzifEl+DmZ7z2/w5m2EtLjwQx5PNYql4YHnBpTTeRIoiQsxa/AJA45avRAy38AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Pie chart of breakdown of profit by region. USA Central: 605,500; USA Northeast: 611,800; USA Southeast: 1,323,000” Additionally, consider adding a caption below the data viz to describe in greater detail what its main takeaway is. Ex \"USA Southeast accounts for 52% of total profit.",
            "title": "Pie chart of breakdown of profit by region. USA Central: 605,500; USA Northeast: 611,800; USA Southeast: 1,323,000” Additionally, consider adding a caption below the data viz to describe in greater detail what its main takeaway is. Ex \"USA Southeast accounts for 52% of total profit.",
            "src": "/static/79628aaf685e38e6c20aca3b0384a895/50383/alt-text-example-graph.png",
            "srcSet": ["/static/79628aaf685e38e6c20aca3b0384a895/1efb2/alt-text-example-graph.png 370w", "/static/79628aaf685e38e6c20aca3b0384a895/50383/alt-text-example-graph.png 740w", "/static/79628aaf685e38e6c20aca3b0384a895/64d87/alt-text-example-graph.png 818w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Describe the title/purpose for the chart and provide an overview of the data. Ex. “Pie chart of
breakdown of profit by region. USA Central: 605,500; USA Northeast: 611,800; USA Southeast:
1,323,000” Additionally, consider adding a caption below the data viz to describe in greater
detail what its main takeaway is. Ex "USA Southeast accounts for 52% of total profit.”`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Just say it’s a chart or what type of chart it is. That's like setting up a joke, but leaving out
the punch line! Ex “This is a pie chart”`}</li>
      </ul>
    </Dont>
    <h2 {...{
      "id": "documenting-alt-text-and-non-text-content-labels"
    }}>{`Documenting Alt-Text and Non-Text Content Labels`}</h2>
    <p>{`Now that you have an understanding of how to write alt-text and labels, you’ll need documentation to
align with development teams and ensure the labels are properly included in the code. A great way to
do this is to include labels for non-text content as a part of the design spec.`}</p>
    <p>{`In the following example from Enter Time, the designer used annotation bubbles to number each piece
of non-text content in the UI screen and then included a legend on the side with the same numbering
system to list out the label that corresponds to each element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/64c0f3c2d8d061bb0c9dd7a3174e0fd0/1078c/alt-text-documentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.783783783783775%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABa0lEQVR42qWSW0vDMBTH+/2/wGSbe1AEmfPypCBeYOyCKENBUHySzerYWtIla9M0Tf4m0UonnXswcEjO7ZdzcuIFQYAwDJ1IKWEXjynoeAzJYqcjzQCt8edS0sV4jDHkef5l+865eiJonb6idT5G7XqK+tkEtct31LsBmj2CZj9Ca0ixPWBoDCgaNwm2LmYIFgIeIQRJkjhQUcTwhaDT83HQ97HbnaDd/8DxiOBoFOHkYYnD0QJ7wznadxH2bwl2BjN07mNEIYenDUUptVK9tUEbm87NWULDdqCdTqO52YRxZwYwhUgYVJaYOBNrOvUqn8MALbO8W8nNxTROkIoMIpNYsCWWCXe6zJUrrBqo1Fop/HaAumQrOqsE2iHZQO0qXBVrt/5Cyhf9G2jPnHP4vg9Kqctd23KRvAko0hRT/w0knG8G/ky8wi8dMAcXEn7IEDDhBri25fL7/Bbrs0BtgBFXeDTFPYf2F2h8AuPjU1NNLxaFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of non-text content design spec with annotated screenshot and list of labels on the side",
            "title": "Screenshot of non-text content design spec with annotated screenshot and list of labels on the side",
            "src": "/static/64c0f3c2d8d061bb0c9dd7a3174e0fd0/50383/alt-text-documentation.png",
            "srcSet": ["/static/64c0f3c2d8d061bb0c9dd7a3174e0fd0/1efb2/alt-text-documentation.png 370w", "/static/64c0f3c2d8d061bb0c9dd7a3174e0fd0/50383/alt-text-documentation.png 740w", "/static/64c0f3c2d8d061bb0c9dd7a3174e0fd0/1078c/alt-text-documentation.png 2180w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "best-practices-for-alt-text-and-non-text-content-label-documentation"
    }}>{`Best Practices for Alt-Text and Non-Text Content Label Documentation:`}</h3>
    <ul>
      <li parentName="ul">{`Focus on the outcome and desired experience.`}</li>
      <li parentName="ul">{`Try not to prescribe a technical solution. Annotations should be non-technical in language—avoid
using specific terms like 'aria-label' as that's not always the best way to add a label.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      